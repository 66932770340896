<template>
  <default-layout :breadcrumbs="breadcrumbs" :useFooter="false">
    <h1 style="display: none">{{ $t('general.searchPage') }}</h1>
    <div class="container">
      <!--      <div>-->
      <!--        <maps :projects="projects" :meta="meta" :mapTypeControl="true" :isLoading="isLoading" />-->
      <!--      </div>-->
      <div class="search--container">
        <search-header></search-header>
        <div class="d-flex justify-space-between">
          <div class="left--col">
            <search-content></search-content>
          </div>
          <div class="right--col" :class="{ active: isActive }">
            <search-filters></search-filters>
          </div>
        </div>
      </div>
    </div>
    <popup-compare></popup-compare>
    <modal-compare></modal-compare>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
    <script v-html="jsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import SearchHeader from '@/components/search/header';
import SearchContent from '@/components/search/content';
import SearchFilters from '@/components/search/filters';
const PopupCompare = () => import('@/components/search/compare-popup');
const ModalCompare = () => import('@/components/search/modals/compare-modal');

import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  name: 'search-page',
  mixins: [HelperMixin],
  components: {
    DefaultLayout,
    SearchHeader,
    SearchContent,
    SearchFilters,
    PopupCompare,
    ModalCompare,
    // Maps,
  },
  computed: {
    ...mapState({
      isActive: (state) => state.v2.search.activeFilterSidebar,
      projects: (state) => state.v2.search.projects,
      listings: (state) => state.v2.search.listings,
      meta: (state) => state.v2.search.meta,
      isLoading: (state) => state.global.isLoading,
      selectedListingType: (state) => state.v2.search.selectedListingType,
      selectedPropertyType: (state) => state.v2.search.selectedPropertyType,
    }),
    typeString() {
      // return this.$t('general.buy');
      return this.$t('general.search');
    },
    propertyTypeString() {
      return this.selectedPropertyType ? this.selectedPropertyType.name : this.$t('general.all');
    },
    ownerName() {
      const owner = this.$store.state.v2.profile.owner;
      let name = '';
      if (owner.name) {
        name = owner.name;
      }
      if (owner.fullname) {
        name = owner.fullname;
      }
      return name;
    },
    breadcrumbs() {
      if (this.$route.name === 'search') {
        return [
          {
            text: this.$t('breadcrumb.homepage'),
            to: '/',
          },
          {
            text: `${this.typeString} ${this.propertyTypeString} ${this.$t('general.sold')}`,
            to: this.$router.currentRoute.path,
          },
        ];
      } else if (this.$route.name === 'search-user-project') {
        return [
          {
            text: this.$t('breadcrumb.homepage'),
            to: '/',
          },
          {
            text: this.ownerName,
            to: this.$router.currentRoute.path,
          },
        ];
      } else if (this.$route.name === 'search-agent-catalog') {
        return [
          {
            text: this.$t('breadcrumb.homepage'),
            to: '/',
          },
          {
            text: this.ownerName,
            to: this.$router.currentRoute.path,
          },
        ];
      }
      return [];
    },
    jsonldBreadcrumb() {
      let baseUrl = this.$store.state.global.baseUrl;
      let text = `${this.typeString} ${this.propertyTypeString} ${this.$t('general.sold')}`;
      if (
        this.$route.name === 'search-user-project' ||
        this.$route.name === 'search-agent-catalog'
      ) {
        text = this.ownerName;
      }
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('breadcrumb.homepage'),
            item: baseUrl + '/',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: text,
            item: baseUrl + this.$router.currentRoute.path,
          },
        ],
      };
    },
    jsonld() {
      let baseUrl = this.$store.state.global.baseUrl;
      let path = this.$router.currentRoute.path;
      const seoTitle = this.$store.getters['v2/search/seoTitle'];
      const seoDesc = this.$store.getters['v2/search/seoDesc'];

      let itemList = [],
        i = 1;
      for (let project of this.projects) {
        itemList.push({
          '@type': 'ListItem',
          position: i,
          item: this.getProjectJsonld({
            baseUrl: baseUrl,
            path: project.links.detail,
            project: project,
            galleries: project.photos,
          }),
        });
        i++;
      }
      for (let listing of this.listings) {
        itemList.push({
          '@type': 'ListItem',
          position: i,
          item: this.getListingJsonld({
            baseUrl: baseUrl,
            path: listing.links.detail,
            listing: listing,
            galleries: listing.photos,
          }),
        });
        i++;
      }

      return itemList.length > 0
        ? {
            '@type': 'RealEstateListing',
            description: seoDesc,
            name: seoTitle,
            url: baseUrl + path,
            mainEntity: {
              '@type': 'ItemList',
              itemListElement: itemList,
            },
          }
        : {};
    },
  },
  async fetch({ store, params, route, query, error }) {
    console.log(
      'Panggil get search project dari fetch di search/index.vue dengan isi route: ',
      route,
    );

    store.commit('global/SET_LOADING', true);

    store.commit('v2/search/set_projects', { data: [], meta: null });
    store.commit('v2/search/set_premier_projects', []);
    store.commit('v2/search/set_projects', { data: [], meta: null });

    let allPromises = [];
    const categoryId = parseInt(query.property_category);
    await store.dispatch('v2/search/setSelectedCategoryId', {
      payload: categoryId,
      query: query,
    });

    try {
      await Promise.all(allPromises);
    } catch (e) {
      error(new Error(e.message), 404);
    }

    let newParams = route.params;
    let owner = null;
    if (params.userHashId) {
      try {
        owner = await store.dispatch('v2/profile/getOwner', params.userHashId);
        if (owner.preferred_project_type) {
          store.commit('v2/search/SET_PROJECT_TYPE', owner.preferred_project_type);
        }
        if (owner && owner.uuid) params.userUuid = owner.uuid;
      } catch (e) {
        error(new Error(e.message), 404);
      }
    }
    if (params.agentHashId) {
      try {
        owner = await store.dispatch('v2/profile/getAgent', params.agentHashId);
        if (!query.listing_type_id) {
          query.listing_type_id = 2;
        }
        if (owner && owner.uuid) params.agentUuid = owner.uuid;
      } catch (e) {
        error(new Error(e.message), 404);
      }
    }

    await store.dispatch('v2/masters/getPropertyTypes');

    allPromises = [];
    allPromises.push(
      store.dispatch('v2/search/getSearchProject', {
        query: query,
        params: newParams,
      }),
    );
    if (store.state.global.loggedIn) {
      allPromises.push(
        store.dispatch('v2/search/getShortlistUuids', {
          listingType: store.getters['v2/search/listingType'],
        }),
      );
    }

    try {
      await Promise.all(allPromises);
    } catch (e) {
      error(new Error(e.message), 404);
    }
    store.commit('global/SET_LOADING', false);

    if (route.name === 'search') {
      const seoTitle = store.getters['v2/search/seoTitle'];
      const seoDesc = store.getters['v2/search/seoDesc'];
      const baseUrl = store.state.global.baseUrl;

      const head = {
        title: seoTitle,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: seoDesc,
          },
          {
            hid: 'og-description',
            property: 'og:description',
            content: seoDesc,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    } else if (route.name === 'search-user-project' || route.name === 'search-agent-catalog') {
      const total = store.state.v2.search.totalUnit;
      const seoTitle = `${total} Properti Dijual oleh ${
        owner ? owner.name : 'Agen BeliRumah'
      } | BeliRumah`;
      const seoDesc = `${total} Properti Dijual oleh ${
        owner ? owner.name : 'Agen BeliRumah'
      }. Cari Properti Dijual di Jakarta, Bogor, Depok, Tangerang, Bekasi dan Seluruh Indonesia. | BeliRumah`;
      const baseUrl = store.state.global.baseUrl;

      const head = {
        title: seoTitle,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: seoDesc,
          },
          {
            hid: 'og-description',
            property: 'og:description',
            content: seoDesc,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: baseUrl + route.fullPath,
            id: 'canonical',
          },
        ],
      };
      store.commit('head/SET_CONTENT', head);
    } else {
      store.commit('head/RESTORE_DEFAULT_VALUE', route);
    }
  },
  async mounted() {
    await this.mountSearch();
  },
  methods: {
    // Fungsi ini harus tetap dipanggil karena state.global.loggedIn di fetch belum update saat pertama load page.
    async mountSearch() {
      if (this.$store.state.global.loggedIn) {
        await this.$store.dispatch('v2/search/getShortlistUuids', {
          listingType: this.$store.getters['v2/search/listingType'],
        });
      }
    },
  },
};
</script>
