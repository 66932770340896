<template>
  <div class="featured--agent-list">
    <div class="list--title" v-if="featuredAgentList && featuredAgentList.length > 0">
      {{ $t('general.featuredAgentT') }}
    </div>
    <router-link
      v-for="featuredAgent in featuredAgentList"
      :key="featuredAgent.uuid"
      :to="featuredAgent.catalog_url"
      style="text-decoration: none"
    >
      <div class="card--project card--agent mb-3">
        <div class="card-body">
          <div class="wrapper--info d-flex align-center">
            <div class="d-flex justify-center">
              <img
                :src="featuredAgent.photo_profile_user"
                v-if="featuredAgent.photo_profile_user"
                :alt="featuredAgent.name"
                class="agent--img"
              />
            </div>
            <div class="ml-3">
              <div class="agent--name">
                {{ featuredAgent.name }}
              </div>
              <div class="d-flex align-center mb-1">
                <div
                  class="agent--company-logo"
                  v-if="featuredAgent.agent_office && featuredAgent.agent_office.logo_url"
                >
                  <img
                    :src="featuredAgent.agent_office.logo_url"
                    :alt="featuredAgent.agent_office.name"
                  />
                </div>
                <div>
                  <div class="agent--company" v-if="featuredAgent.agent_office">
                    {{ featuredAgent.agent_office.name }}
                  </div>
                  <div class="agent--company" v-else>
                    {{ $t('general.independentAgent') }}
                  </div>
                </div>
              </div>
              <span class="agent--area">
                {{ featuredAgent.area }}
              </span>
            </div>
          </div>
          <div class="featured--right">
            <img src="@/assets/img/icons/chevron-right-2.svg" alt="right-chevron" />
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  props: {},
  data: () => ({}),
  computed: {
    ...mapState({
      featuredAgentList: (state) => state.v2.search.featuredAgent.list,
    }),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.featured--agent-list {
  margin-top: 24px;
}
.list--title {
  margin-bottom: 16px;
  font-family: Poppins-SemiBold, sans-serif;
  font-size: $font-xl;
}
.card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
}
.card--agent {
}
.agent--img {
  width: 72px;
  height: 72px;
  object-fit: contain;
  border: solid 1px $color-gray-1;
  border-radius: 50%;
  @media #{$phones} {
    width: 60px;
    height: 60px;
  }
}
.agent--name {
  font-family: Poppins-Bold, sans-serif;
  font-size: $font-md;
  color: $color-text-black;
  margin-bottom: 4px;
  @media #{$phones} {
    font-size: $font-sm;
  }
}
.agent--company {
  font-family: Poppins-SemiBold, sans-serif;
  font-size: $font-sm;
  color: $color-text-black;
  @media #{$phones} {
    font-size: $font-xs;
  }
}
.agent--company-logo {
  max-width: 32px;
  height: 32px;
  img {
    max-width: 32px;
    height: 32px;
    margin-right: 8px;
  }
}
.agent--area {
  font-family: Poppins-Bold, sans-serif;
  font-size: $font-sm;
  color: $color-beliruma-1;
  @media #{$phones} {
    font-size: $font-xs;
  }
}
.featured--right {
  img {
    height: 24px;
    margin-right: 8px;
  }
}
</style>
